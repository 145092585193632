import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import ProductIcon from "../components/productIcon"

const IndexPage = () => {

    const productData = require('../../data/products.json');
    const products = productData.filter(({ }, i) => i < 6);

    return < Layout >
        <section class="bg-white">
            <div
                class="mx-auto max-w-screen-xl px-4 py-32 my-16 lg:flex lg:h-max lg:items-center"
            >
                <div class="mx-auto max-w-2xl text-center">
                    <h1 class="text-3xl font-extrabold sm:text-5xl">
                        About the Irishman Cloud
                    </h1>

                    <p class="mt-4 sm:text-xl sm:leading-relaxed">
                        Learn more about what makes us a leading SaaS and Cloud Operations provider, and why we are the first choice of many.
                    </p>
                </div>
            </div>
        </section>
        <section class="bg-white">
            <div class="mx-auto mb-16 max-w-4xl text-start">
                <h2 class="text-3xl font-extrabold sm:text-4xl">Core Beliefs</h2>
                <p class="mt-4 sm:text-xl sm:leading-relaxed">We have two main beliefs that we hold dear to us at Irishman Cloud. These core beliefs inform how we make business decisions, develop new products, take on investors, and even hire new employees.</p>
            </div>
            <div class="mx-auto mb-16 max-w-4xl text-start">
                <h3 class="text-2xl font-extrabold sm:text-3xl">
                    We Do Well, When You Do Well
                </h3>
                <p class="mt-4 sm:text-xl sm:leading-relaxed">
                    One of the great things about Irishman is how cost effective it really is. Our main focus is with providing your organization ways to increase productivity, but we feel that most performance enhancing tools do this while also relying on price gouging. It would feel wrong for us to price gouge, and so, we decided as a company that 'we do well when you do well'. Or in other words, we want to help you as a user to the best of our ability.
                </p>
                <p class="mt-4 sm:text-xl sm:leading-relaxed">
                    We are proud to say that, for our employees, this belief holds true as well! We understand that our employees are people and because of this, we try to make working for Irishman Cloud as enjoyable as we can. The best work a person can do is when they are happy, and so, to stay ahead of our competition, we keep our employees happy.
                </p>
            </div>
            <div class="mx-auto mb-16 max-w-4xl text-start">
                <h3 class="text-2xl font-extrabold sm:text-3xl">
                    Punch Up
                </h3>
                <p class="mt-4 sm:text-xl sm:leading-relaxed">
                    This belief sounds rather vague, but what we mean by this is straightforward. We try to design everything so that it can sustain itself, and support other designs that expand it. For our products, this means that we try to give you as many options as possible. So, when we send data, we always will give more than enough. When we perform a task, we will allow you to fine-tune each step. And when you want to combine tools, they will work together in the quickest way possible.
                </p>
                <p class="mt-4 sm:text-xl sm:leading-relaxed">
                    But, most importantly, it means that each of our programmers writes elegant and self supporting code (as in each program is a work of art). The reason for this? We want to know that every member of Irishman Cloud could take a week-long vacation (maybe go to Hawaii and sip Martinis by the pool), and during this trip, the company could run itself.
                </p>
            </div>
        </section>
    </Layout >
}

export const Head = () => <Seo title="About" />

export default IndexPage
